var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "jeopardai-questions" }, [
    _vm.isViewerHostLike
      ? _c("div", { staticClass: "jeopardai-questions__controls" }, [
          _c(
            "div",
            {
              staticClass:
                "jeopardai-questions__controls-btn jeopardai-questions__controls-btn--round",
              class: {
                "jeopardai-questions__controls-btn--disabled": _vm.round === 1,
              },
              on: {
                click: [
                  function ($event) {
                    if (
                      $event.ctrlKey ||
                      $event.shiftKey ||
                      $event.altKey ||
                      $event.metaKey
                    ) {
                      return null
                    }
                    return _vm.up()
                  },
                  function ($event) {
                    if (!$event.shiftKey) {
                      return null
                    }
                    return _vm.down()
                  },
                ],
              },
            },
            [
              _c("i", { staticClass: "fas fa-circle" }),
              _vm.round === 1
                ? _c("i", { staticClass: "mdi mdi-arrow-right-bold" })
                : _vm._e(),
              _c("i", {
                staticClass:
                  "mdi mdi-crown jeopardai-questions__controls-btn-inner-icon",
              }),
              _c(
                "span",
                { staticClass: "jeopardai-questions__controls-btn-inner-text" },
                [_vm._v(" 1 ")]
              ),
            ]
          ),
          !(_vm.predefined && !_vm.secondRound)
            ? _c(
                "div",
                {
                  staticClass:
                    "jeopardai-questions__controls-btn jeopardai-questions__controls-btn--round",
                  class: {
                    "jeopardai-questions__controls-btn--disabled":
                      _vm.round === 2,
                  },
                  on: {
                    click: [
                      function ($event) {
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        ) {
                          return null
                        }
                        return _vm.up()
                      },
                      function ($event) {
                        if (!$event.shiftKey) {
                          return null
                        }
                        return _vm.down()
                      },
                    ],
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-circle" }),
                  _vm.round === 2
                    ? _c("i", { staticClass: "mdi mdi-arrow-right-bold" })
                    : _vm._e(),
                  _c("i", {
                    staticClass:
                      "mdi mdi-crown jeopardai-questions__controls-btn-inner-icon",
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "jeopardai-questions__controls-btn-inner-text",
                    },
                    [_vm._v(" 2 ")]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "jeopardai-questions__controls-btn jeopardai-questions__controls-btn--finale",
              on: {
                click: function ($event) {
                  return _vm.goToFinale()
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-circle" }),
              _c("i", {
                staticClass:
                  "fas fa-trophy jeopardai-questions__controls-btn-inner-icon",
              }),
            ]
          ),
          !_vm.predefined
            ? _c(
                "div",
                {
                  staticClass:
                    "jeopardai-questions__controls-btn jeopardai-questions__controls-btn--regenerate",
                  on: {
                    click: function ($event) {
                      return _vm.regenerate()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#fff" },
                  }),
                  _c("i", {
                    staticClass:
                      "mdi mdi-swap-horizontal-bold jeopardai-questions__controls-btn-inner-icon",
                  }),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "jeopardai-questions__controls-btn jeopardai-questions__controls-btn--back",
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas fa-circle",
                staticStyle: { color: "#fff" },
              }),
              _c("i", {
                staticClass:
                  "fas fa-chevron-left jeopardai-questions__controls-btn-inner-icon",
                staticStyle: { color: "#000" },
              }),
            ]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "jeopardai-questions__columns" },
      _vm._l(_vm.questionGroupedByCategory, function (group) {
        var _obj, _obj$1
        return _c(
          "div",
          {
            key: group.title,
            staticClass: "jeopardai-questions__column",
            class:
              ((_obj = {}),
              (_obj["jeopardai-questions__column--selected"] = group.selected),
              (_obj["jeopardai-questions__column--selectable"] =
                _vm.hasRightToSelectCategory),
              _obj),
            style: { zIndex: group.index },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "jeopardai-questions__card jeopardai-questions__card--category pa-1",
                class:
                  ((_obj$1 = {}),
                  (_obj$1["jeopardai-questions__card--selected-category"] =
                    group.selected),
                  (_obj$1["jeopardai-questions__card--selectable"] =
                    _vm.hasRightToSelectCategory),
                  _obj$1),
                on: {
                  click: function ($event) {
                    _vm.hasRightToSelectCategory &&
                      _vm.$emit("category", group.title)
                  },
                },
              },
              [
                _vm.isViewerHostLike
                  ? [
                      _c("JeopardAiTextAreaTitle", {
                        attrs: { value: group.title },
                        on: {
                          input: function (value) {
                            return _vm.updateCategory(group.title, value)
                          },
                        },
                      }),
                      group.generating
                        ? _c("JeopardAiLoadingAnimation", {
                            staticClass: "jeopardai-questions__card-gears",
                          })
                        : !_vm.predefined
                        ? _c("i", {
                            staticClass:
                              "mdi mdi-swap-horizontal-bold jeopardai-questions__card-replace-btn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.regenerate(group.title)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _c("JeopardAiTitle", { attrs: { value: group.title } }),
              ],
              2
            ),
            _vm._l(group.questions, function (question) {
              var _obj
              return _c(
                "div",
                {
                  key: question.id,
                  staticClass: "jeopardai-questions__card",
                  class:
                    ((_obj = {}),
                    (_obj["jeopardai-questions__card--selected"] =
                      question.id === _vm.questionId),
                    (_obj["jeopardai-questions__card--selectable"] =
                      _vm.hasRightToSelectQuestion(question)),
                    (_obj["jeopardai-questions__card--current"] =
                      question.current),
                    (_obj["jeopardai-questions__card--dimmed"] =
                      question.status === _vm.QuestionStatus.CORRECT ||
                      question.status === _vm.QuestionStatus.WRONG ||
                      question.status === _vm.QuestionStatus.SKIPPED),
                    _obj),
                  attrs: {
                    "data-tooltip": _vm.isViewerHostLike
                      ? question.answer + " " + question.text
                      : undefined,
                  },
                  on: {
                    click: function ($event) {
                      _vm.hasRightToSelectQuestion(question) &&
                        _vm.notifyAndGo(question)
                    },
                  },
                },
                [
                  (question.status !== _vm.QuestionStatus.WRONG &&
                    question.status !== _vm.QuestionStatus.CORRECT &&
                    question.status !== _vm.QuestionStatus.SKIPPED) ||
                  _vm.isViewerHostLike
                    ? [
                        _c("div", [
                          _c("i", {
                            staticClass:
                              "fas fa-times-circle jeopardai-questions__card-icon jeopardai-questions__card-icon--wrong",
                            class:
                              question.status === _vm.QuestionStatus.WRONG
                                ? "jeopardai-questions__card-icon--visible"
                                : null,
                            attrs: { stlye: "" },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "jeopardai-questions__amount mr-2 ml-2",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "jeopardai-questions__amount-currency-sign",
                              },
                              [_vm._v("$")]
                            ),
                            _vm._v(
                              _vm._s(_vm.addCommasToNumber(question.amount)) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", [
                          _c("i", {
                            staticClass:
                              "fas fa-check-circle jeopardai-questions__card-icon jeopardai-questions__card-icon--correct",
                            class:
                              question.status === _vm.QuestionStatus.CORRECT
                                ? "jeopardai-questions__card-icon--visible"
                                : null,
                          }),
                        ]),
                        question.double && _vm.isViewerHostLike
                          ? _c(
                              "div",
                              { staticClass: "jeopardai-questions__card-x2" },
                              [_vm._v(" x2 ")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

































































































































































import {
  defineComponent,
  computed,
  toRefs,
  watch,
  onMounted,
  ref,
  onBeforeUnmount
} from "@vue/composition-api"

import type { PropType } from "@vue/composition-api"
import { chain, sortBy } from "lodash"

import {
  serverTimestamp,
  update,
  runTransaction,
  get,
  child,
  push,
  onValue,
  set,
  increment
} from "firebase/database"

import type { Unsubscribe } from "firebase/database"

import useViewer from "../use/useViewer"
import useIsViewerHostLike from "../use/useIsViewerHostLike"
import useJeopardAiRound from "../use/useJeopardAiRound"
import useJeopardAiRoundRef from "../use/useJeopardAiRoundRef"
import useJeopardAiRootRef from "../use/useJeopardAiRootRef"
import useJeopardAiBaseRef from "../use/useJeopardAiBaseRef"
import useJeopardAiAnswers from "../use/useJeopardAiAnswers"
import useJeopardAiTeamId from "../use/useJeopardAiTeamId"
import useJeopardAiState from "../use/useJeopardAiState"
import { useConfirm } from "@/use"
import useStore from "@/use/useStore"

import JeopardAiTitle from "./JeopardAiTitle.vue"
import JeopardAiTextAreaTitle from "./JeopardAiTextAreaTitle.vue"
import JeopardAiLoadingAnimation from "./JeopardAiLoadingAnimation.vue"

import { addCommasToNumber } from "../helpers"

import type { Question, Job } from "../types"
import { Module, JobType } from "../enums"
import Sfx from "@shared/Sfx"

import {
  NotificationMixin,
  NotificationScope,
  NotificationType
} from "@/mixins/NotificationMixin"
import useTeams from "@/use/useTeams"
import useJeopardPredefined from "../use/useJeopardPredefined"

enum QuestionStatus {
  CORRECT,
  WRONG,
  UNPLAYED,
  SKIPPED
}

type QuestionWithStatus = Question & {
  status: QuestionStatus
  current: boolean
}

type Progress = Record<string, { timestamp: number; queue: any }>

export default defineComponent({
  name: "JeopardAiQuestions",
  emits: ["category"],
  components: {
    JeopardAiTitle,
    JeopardAiLoadingAnimation,
    JeopardAiTextAreaTitle
  },
  mixins: [NotificationMixin],
  props: {
    questions: {
      type: Array as PropType<Question[]>,
      required: true
    },
    category: {
      type: String,
      required: false
    },
    questionId: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const { questions, category } = toRefs(props)
    const { confirm } = useConfirm()
    const { teams } = useTeams()
    const { answers } = useJeopardAiAnswers()
    const { round } = useJeopardAiRound()
    const { ref: baseRef } = useJeopardAiBaseRef()
    const { ref: rootRef } = useJeopardAiRootRef(round)
    const { ref: roundRef } = useJeopardAiRoundRef()
    const { teamId } = useJeopardAiTeamId()
    const { state } = useJeopardAiState()
    const { store } = useStore()
    const { predefined, secondRound, final, getFinalQuestion } =
      useJeopardPredefined()

    const jobs = ref<Record<string, Job>>({})
    const viewerTeam = computed(() => teams.value[viewer.value.teamID])

    // question progress data
    const progress = ref<Progress | undefined>()

    const loading = ref(true)

    const { viewer } = useViewer()
    const { isViewerHostLike } = useIsViewerHostLike()

    const successorTeamId = computed<string | undefined>(() => {
      const answer = answers.value
        .sort((a, b) => b.timestamp - a.timestamp)
        .find(({ correct }) => correct)

      return answer?.teamId
    })

    watch(
      successorTeamId,
      value => {
        teamId.value = value
      },
      { immediate: true }
    )

    const scribes = computed(() => store.state.JeopardAi.scribes ?? [])

    const hasRightToSelectCategory = computed(() => {
      if (isViewerHostLike.value) return true
      if (scribes.value.some(user => user.id === viewer.value.id)) return true
      return false
    })

    const hasRightToSelectQuestion = computed(() => {
      return (question: QuestionWithStatus) => {
        if (isViewerHostLike.value) return true
        if (loading.value) return false
        if (question.status !== QuestionStatus.UNPLAYED) return false
        if (scribes.value.some(user => user.id === viewer.value.id)) return true
        return false
      }
    })

    const subscriptions: Unsubscribe[] = []

    onBeforeUnmount(() => {
      subscriptions.forEach(unsubscribe => unsubscribe())
    })

    async function regenerate(category?: string, message = true) {
      if (category != null) {
        if (message) {
          const confirmed = await confirm({
            message: `Would you like to regenerate questions for ${category} category?`,
            title: "System"
          })

          if (confirmed === false) return
        }

        const jobId = push(child(rootRef.value, `jobs`)).key
        const jobRef = child(rootRef.value, `jobs/${jobId}`)
        const job: Job = {
          id: jobId,
          type: JobType.UPDATE_CATEGORY_QUESTIONS,
          payload: category
        }

        const unsubscribe = onValue(jobRef, snapshot => {
          const value = snapshot.val() as Job
          if (value == null) return
          jobs.value = { ...jobs.value, [jobId]: value }
          if (value.message) alert(value.message)
          if (value.completed === true) unsubscribe()
        })

        subscriptions.push(unsubscribe)

        set(jobRef, job)

        return
      }

      const confirmed = await confirm({
        message: `Would you like to regenerate questions for this board?`,
        title: "System"
      })

      if (confirmed === false) return

      update(
        child(baseRef.value, "answers"),
        answers.value.reduce((acc, val) => {
          if (val.round !== round.value) return acc
          acc[val.id] = null
          return acc
        }, {})
      )

      update(rootRef.value, {
        [`question`]: null,
        [`state/questions`]: null,
        [`state/finale`]: null,
        [`state/questionId`]: null,
        [`state/userId`]: null,
        [`state/teamId`]: null,
        [`state/timestamp`]: serverTimestamp()
      })
    }

    function goToQuestion(question: Question) {
      if (question.double) playSfx(Sfx.DAILY_DOUBLE)

      update(rootRef.value, {
        [`question/${question.id}/timestamp`]: serverTimestamp(),
        [`state/module`]: Module.ANSWERS,
        [`state/questionId`]: question.id,
        [`state/category`]: question.category,
        [`state/timestamp`]: serverTimestamp(),
        [`state/userId`]: isViewerHostLike.value
          ? null
          : viewer.value?.id ?? null,
        [`state/teamId`]: isViewerHostLike.value
          ? store.getters["group/globalTeamID"] ?? null
          : viewer.value?.teamID ?? null,
        [`state/version`]: increment(1),
        queue: null
      })
    }

    onMounted(async () => {
      if (
        isViewerHostLike.value &&
        successorTeamId.value != null &&
        successorTeamId.value != store.getters["group/globalTeamID"]
      ) {
        store.dispatch("group/updateCurrentGlobalTeam", successorTeamId.value)
      }

      const snapshot = await get(child(rootRef.value, "question"))
      progress.value = snapshot.val()
      loading.value = false
    })

    const categories = computed(() => sortBy(state.value?.categories ?? []))

    const questionGroupedByCategory = computed(() => {
      function getQuestionStatus(question: Question): QuestionStatus {
        const values = answers.value.filter(
          answer => answer.questionId === question.id
        )

        if (values.some(answer => answer.correct)) return QuestionStatus.CORRECT

        if (values.some(answer => !answer.correct)) return QuestionStatus.WRONG

        if (progress.value && progress.value[question.id] != null)
          return QuestionStatus.SKIPPED

        return QuestionStatus.UNPLAYED
      }

      const currentQuestionId = state.value?.questionId

      const grouped = chain(questions.value)
        .map(question => ({
          ...question,
          current: currentQuestionId === question.id,
          status: getQuestionStatus(question)
        }))
        .groupBy("category")
        .value() as unknown as Record<string, QuestionWithStatus[]>

      const categoriesJobs = Object.values(jobs.value ?? {}).filter(
        job => job.type === JobType.UPDATE_CATEGORY_QUESTIONS
      )

      return categories.value.map((title, index) => ({
        title,
        index:
          title === category.value
            ? categories.value.length + 1
            : categories.value.length - index,
        selected: title === category.value,
        generating: categoriesJobs.some(
          job => job.payload === title && job.completed !== true
        ),
        questions: (grouped[title] ?? []).sort((a, b) => a.amount - b.amount)
      }))
    })

    function playSfx(sfx) {
      store.dispatch("soundeffect/updateGameSoundEffect", sfx)
    }

    async function up() {
      const confirmed = await confirm({
        message: `Would you like to go to the next round?`,
        title: "System"
      })
      if (confirmed === false) return
      await runTransaction(roundRef.value, value => {
        if (value == null) return 2
        if (value < 1) return
        return value + 1
      })

      if (predefined.value) {
        const { questions, categories } = secondRound.value
        await update(rootRef.value, {
          [`state/module`]: Module.QUESTIONS,
          [`state/questions`]: questions,
          [`state/categories`]: categories,
          [`state/timestamp`]: serverTimestamp(),
          [`state/working`]: false
        })
      }
    }

    async function down() {
      const confirmed = await confirm({
        message: `Would you like to go to the previous round?`,
        title: "System"
      })
      if (confirmed === false) return
      runTransaction(roundRef.value, value => {
        if (value == null) return
        if (value <= 1) return
        return value - 1
      })
    }

    async function goToFinale() {
      const confirmed = await confirm({
        message: `Would you like to go to the final question?`,
        title: "System"
      })

      if (confirmed === false) return

      console.log(`predefined`, predefined.value)
      console.log(`final.value`, final.value)

      const payload: Record<string, any> = {
        [`state/module`]: Module.FINALE,
        [`state/questionId`]: state.value?.finale?.id ?? null,
        [`state/category`]: null,
        [`state/timestamp`]: serverTimestamp(),
        [`state/userId`]: null,
        [`state/teamId`]: null,
        queue: null
      }

      if (predefined.value) {
        const categories = (final.value?.categories ?? []) as string[]

        if (categories.length === 1) {
          const category = categories[0]
          payload[`state/category`] = category
          payload[`state/finale`] = {
            ...getFinalQuestion(category),
            id: serverTimestamp()
          }
          payload[`state/questionId`] = serverTimestamp()
        }

        payload[`state/categories`] = categories
      }

      update(rootRef.value, payload)
    }

    async function updateCategory(before: string, after: string) {
      if (
        before.trim().toLocaleLowerCase() === after.trim().toLocaleLowerCase()
      )
        return
      const values = categories.value.filter(value => value !== before)
      values.push(after)
      await update(rootRef.value, {
        [`state/categories`]: values,
        ...questions.value.reduce((acc, val) => {
          if (val.category !== before) return acc
          acc[`state/questions/${val.id}/category`] = after
          return acc
        }, {} as Record<string, string>)
      })
      await regenerate(after, false)
    }

    function back() {
      emit("navigateToPreviusStep")
    }

    return {
      back,
      updateCategory,
      state,
      round,
      up,
      down,
      regenerate,
      goToFinale,
      goToQuestion,
      addCommasToNumber,
      viewerTeam,
      viewer,
      questionGroupedByCategory,
      hasRightToSelectCategory,
      hasRightToSelectQuestion,
      isViewerHostLike,
      predefined,
      secondRound,
      QuestionStatus
    }
  },
  methods: {
    async notifyAndGo(question: Question) {
      if (!this.isViewerHostLike) {
        const addText = ` ${this.viewerTeam.name} has selected ${
          question.category
        } for $${addCommasToNumber(question.amount)}`
        await this.$_NotificationMixin_send({
          notificationTo: this.$store.getters.gameHost?.id,
          addText,
          type: NotificationType.QUESTION_BY_USER,
          scope: NotificationScope.GLOBAL
        })
      }

      this.goToQuestion(question)
    }
  }
})




























import {
  defineComponent,
  ref,
  nextTick,
  toRefs,
  computed
} from "@vue/composition-api"

export default defineComponent({
  name: "JeopardAiTextAreaTitle",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  emits: {
    input: (data: string) => typeof data === "string"
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)

    const active = ref(false)
    const textarea = ref()

    function focus() {
      active.value = true
      nextTick(() => textarea.value?.focus())
    }

    function submit(e) {
      const value = e.target.value
      if (active.value !== true) return
      active.value = false
      emit("input", value)
    }

    const fontSize = computed(() => {
      let n = 30

      if (value.value.length > 31) {
        n = 18
      } else if (value.value.length > 15) {
        n = 25
      }

      return n
    })

    return { focus, submit, active, textarea, fontSize }
  }
})
